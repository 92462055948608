import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import Caption from "../Title/Caption";
import Heading from "../Title/Heading";
import { TokenVesting } from "token-vesting-api";
import { Connection, PublicKey } from "@solana/web3.js";
import { KeyNamesType, VestingNames } from "../../types";
import { converterBN } from "../../utils";

interface ElementProps {
  name: KeyNamesType;
  newLocalWalletKey: PublicKey;
}

export const Element: React.FC<ElementProps> = ({
  name,
  newLocalWalletKey,
}) => {
  const [qty, setQty] = useState<string>("0");

  const getAvailableTokens = async (
    vesting: TokenVesting,
    receiver: PublicKey
  ) => {
    try {
      const tokens = await vesting.getVestingStatistic(receiver);
      const qty = converterBN(tokens.availableToWithdrawTokens);
      console.log(receiver.toString(), qty);
      setQty(qty);
    } catch (error) {
      console.log("getAvailableTokens === ", error);
    }
  };

  useEffect(() => {
    const vestingName = VestingNames[name];
    const tokenVesting = new TokenVesting(
      new Connection(process.env.REACT_APP_NETWORK),
      new PublicKey(process.env.REACT_APP_PROGRAM_ID),
      new PublicKey(process.env.REACT_APP_MINT),
      new PublicKey(newLocalWalletKey),
      new PublicKey(process.env.REACT_APP_CREATOR),
      new PublicKey(process.env.REACT_APP_CREATOR),
      vestingName
    );

    const pk = process.env[`REACT_APP_${VestingNames[name]}`];
    const receiver = pk ? new PublicKey(pk) : newLocalWalletKey;

    getAvailableTokens(tokenVesting, receiver);
    return () => {
      setQty("0");
    };
  }, [name, newLocalWalletKey]);

  return (
    <Container
      sx={{
        flex: 1,
        marginX: { xs: "4px", md: 1 },
        border: "1px solid rgb(219, 219, 219)",
        borderRadius: "5px",
        my: 1,
        minHeight: 100,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Caption text={"Tokens Available to Claim " + name} />
      <Heading sx={{ textAlign: { xs: "center" } }} text={qty + " ELU"} />
    </Container>
  );
};
