import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Chip,
  TextareaAutosize,
  Alert,
  Snackbar,
} from "@mui/material";
import Logo from "../../assets/logo.svg";
import styles from "./RegisterInvestor.module.css";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { EPrivateVestingNames, EVestingNames } from "../../types";
import { Transaction } from "@solana/web3.js";
import Caption from "../../components/Title/Caption";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import {
  createTransaction,
  signMultipleTransactions,
} from "../../utils/transctionsHelper";

type TInvestor = {
  id?: number;
  wallet: string;
  amount: number | string;
  confirmed: boolean | undefined;
};

const RegisterInvestor = () => {
  const [type, setType] = useState<EVestingNames | string>("");
  const [walletKey, setWalletKey] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [textAreaVisible, setTextAreaVisible] = useState<boolean>(true);
  const [isSnackbarVisible, setSnackbarVisible] = useState<boolean>(false);
  const [data, setData] = useState<string>("");
  const [transactionList, setTransactionList] = useState<TInvestor[]>([]);

  const onChangeType = (e: SelectChangeEvent<EVestingNames | string>) => {
    console.log("here");

    const value = e.target.value;
    setType(value);
    if (!textAreaVisible) {
      setTextAreaVisible(true);
      setData("");
      setTransactionList([]);
    }
  };

  useEffect(() => {
    const getWallet = async () => {
      try {
        const resp = await window.solana?.connect();
        const newLocalWalletKey = resp.publicKey.toString();
        setWalletKey(newLocalWalletKey);

        if (newLocalWalletKey !== process.env.REACT_APP_ADMIN) {
          setIsError(true);
          console.log(newLocalWalletKey, process.env.REACT_APP_ADMIN);
        }
      } catch (error) {
        console.log("RegisterInvestor error", error);
        setIsError(true);
      }
    };
    getWallet();
  }, []);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setData(e.target.value);
  };

  const onSaveClick = async () => {
    setLoading(true);
    if (data.includes("\n")) {
      const list = data?.split("\n");
      console.log("list", list);

      const normalizedList =
        list &&
        list
          .filter((el) => !!el)
          .map((el) => {
            const [wallet, amount] = el.split(";");
            const item: TInvestor = {
              amount: amount ?? "",
              wallet: wallet ?? "",
              confirmed: undefined,
            };
            return item;
          });
      if (!normalizedList.length) {
        setLoading(false);
      } else {
        setTextAreaVisible(false);
      }
      console.log("a === ", normalizedList);
      setTransactionList(normalizedList as TInvestor[]);

      const createdTransactionsList: any[] = [];
      let copyList = [...normalizedList];
      for (let index = 0; index < normalizedList.length; index++) {
        const element = normalizedList[index];
        if (walletKey) {
          const createdTransaction = createTransaction(
            element,
            walletKey,
            type
          );
          createdTransactionsList.push(createdTransaction);
        }
      }
      if (createdTransactionsList.length) {
        const resolvedAllTransactions = await Promise.all(
          createdTransactionsList
        );
        const filteredTransactions: any[] = [];

        for (let index = 0; index < resolvedAllTransactions.length; index++) {
          const element = resolvedAllTransactions[index];

          const bool = typeof element === "string";
          if (bool) {
            copyList = copyList.map((el) =>
              el.wallet === element ? { ...el, confirmed: !bool } : el
            );
            setTransactionList(copyList);
          } else {
            filteredTransactions.push(element);
          }
        }
        console.log("filteredTransactions =", {
          filteredTransactions,
          resolvedAllTransactions,
        });
        await sendTransaction(filteredTransactions, copyList);
      }
    } else {
      const [wallet, amount] = data.split(";");
      if (!!+amount) {
        const item: TInvestor = {
          amount,
          wallet,
          confirmed: undefined,
        };
        setTransactionList([item]);
        console.log("item === ", item, transactionList);
        setTextAreaVisible(false);
        if (walletKey) {
          const createdTransaction = createTransaction(item, walletKey, type);

          const resolvedAllTransaction = await Promise.all([
            createdTransaction,
          ]);
          await sendTransaction(resolvedAllTransaction, [item]);
        }
      } else {
        setTextAreaVisible(false);
        handleOpenSnackBar();
        setTimeout(() => {
          handleCloseSnackBar();
          setTextAreaVisible(true);
          setLoading(false);
          setData("");
          setTransactionList([]);
        }, 2000);
      }
    }
  };

  const sendTransaction = useCallback(
    async (
      createdTransactions: Transaction[] | any[],
      listOfTransaction: TInvestor[]
    ) => {
      try {
        const test = await signMultipleTransactions(createdTransactions);
        // const test: any[] = [];
        if (test) {
          console.log("sflsgjsdfjklknlljknsfanasdkfsdjkf", {
            listOfTransaction,
            transactionList,
          });

          const validData = listOfTransaction.map((transaction) => {
            const element = test.find(
              (el) => el.investorPublicKey === transaction.wallet
            );
            if (element) {
              console.log(`transaction`, element.el);
              return {
                ...transaction,
                //@ts-ignore
                confirmed: element.el.status !== "rejected",
              };
            }
            return transaction;
          });
          setTransactionList(validData);
        }

        setLoading(false);
        console.log("djkhfasjkfhjkhfasjkhfdasjkfhdajkhfs", test);
      } catch (error) {
        handleOpenSnackBar();
        console.log("sendTransaction1 error ====>", error);
      }
    },
    [transactionList]
  );

  const handleCloseSnackBar = () => {
    setSnackbarVisible(false);
  };

  const handleOpenSnackBar = () => {
    setSnackbarVisible(true);
  };

  console.log({ transactionList });

  return (
    <Box sx={sxStyles.mainContainer}>
      <Box sx={sxStyles.topContainer}>
        <div className={styles.logo_container}>
          <img alt={"logo"} src={Logo} className={styles.logo} />
        </div>
      </Box>
      <Box sx={sxStyles.wrapper}>
        {!isError ? (
          <Box sx={sxStyles.formContainer}>
            <FormControl disabled={isLoading} fullWidth sx={{ mb: 2 }}>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={type}
                label="Type"
                onChange={onChangeType}
              >
                <MenuItem value={EVestingNames.SEED_SALE}>Seed Sale</MenuItem>
                <MenuItem value={EVestingNames.STRATEGIC}>Strategic</MenuItem>
                <MenuItem value={EPrivateVestingNames.ADVISORY}>
                  Advisory
                </MenuItem>
                <MenuItem value={EVestingNames.PRIVATE}>Private</MenuItem>
                <MenuItem value={EVestingNames.SEED_2}>
                  Seed2
                </MenuItem>
                <MenuItem value={EPrivateVestingNames.MARKETING}>
                  Marketing
                </MenuItem>
                <MenuItem value={EPrivateVestingNames.TEAM}>
                  Team
                </MenuItem>
                <MenuItem value={EPrivateVestingNames.TREASURY}>
                  Treasury
                </MenuItem>
              </Select>
              <Divider />
              {!!type.length && (
                <>
                  <Stack sx={sxStyles.chipContainer}>
                    {transactionList.map((el: TInvestor, i: number) => (
                      <Box key={el.wallet + i}>
                        <Chip
                          sx={sxStyles.chip}
                          color={
                            typeof el.confirmed === "boolean"
                              ? el.confirmed
                                ? "success"
                                : "error"
                              : "default"
                          }
                          label={el.wallet + "-" + el.amount}
                        />
                      </Box>
                    ))}
                  </Stack>
                  {textAreaVisible && (
                    <TextareaAutosize
                      aria-label="Wallet ; Amount"
                      placeholder="Wallet ; Amount"
                      style={sxStyles.area}
                      value={data}
                      onChange={onChange}
                    />
                  )}
                </>
              )}
            </FormControl>
            <LoadingButton
              onClick={onSaveClick}
              sx={sxStyles.loadingButton}
              fullWidth
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              loading={isLoading}
              disabled={!type.length || isLoading || !data.length}
            >
              Save
            </LoadingButton>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FmdBadIcon sx={{ color: "#f7bab9", fontSize: 68 }} />
            <Caption
              sx={{ p: 2 }}
              text={"Sorry, something went wrong. Try again later."}
            />
          </Box>
        )}
      </Box>
      <Snackbar
        sx={sxStyles.snackbar}
        open={isSnackbarVisible}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert severity="error" sx={sxStyles.alert}>
          Invalid Wallet Address or Amount
        </Alert>
      </Snackbar>
    </Box>
  );
};

const sxStyles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  topContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    width: "100%",
  },
  wrapper: {
    width: "100%",
    padding: { xs: 0, md: 0 },
    display: "flex",
    justifyContent: "center",
  },
  formContainer: {
    width: { md: "50%", xs: "100%" },
    minHeight: "300px",
    paddingX: { xs: 1, lg: 2 },
    pb: 3,
    pt: { lg: 0, xs: 1 },
  },
  addIcon: {
    borderRadius: 25,
    // background: "#1395ff",
    background: "black",
    color: "white",
    opacity: "0.5",
    "&.MuiButtonBase-root:hover": {
      opacity: "1!important",
      background: "black",
    },
  },
  row: {
    height: "40vh",
    py: 1,
  },
  loadingButton: {
    height: 56,
    background: "linear-gradient(180deg, #49adff 52.39%, #1395ff 52.4%)",
  },
  chipContainer: {
    pt: 1,
    overflow: "scroll",
    maxHeight: "50vh",
  },
  chip: {
    my: 0.5,
  },
  area: {
    height: "30vh",
    width: "99%",
    margin: 0,
  },
  snackbar: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    left: 0,
    right: 0,
  },
  alert: {
    width: { xs: "90%", md: "40%" },
    display: "flex",
    justifyContent: "center",
  },
} as const;

export default RegisterInvestor;
