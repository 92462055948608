import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  Transaction,
} from "@solana/web3.js";
import BigNumber from "bignumber.js";
import BN from "bn.js";
import { CreateVestingAccountInstruction } from "token-vesting-api/dist/schema";
import { getTokenVesting } from ".";

type TInvestor = {
  id?: number;
  wallet: string;
  amount: number | string;
  confirmed: boolean | undefined;
};

const connection = new Connection(process.env.REACT_APP_NETWORK);

export const createTransaction = async (
  data: TInvestor,
  walletKey: string,
  type: string
) => {
  try {
    const tokenVesting = await getTokenVesting(type);
    if (!tokenVesting) return;

    const transaction: Transaction = await tokenVesting.createVestingAccount(
      new PublicKey(data.wallet),
      new CreateVestingAccountInstruction(
        new BN(
          new BigNumber(data.amount).multipliedBy(LAMPORTS_PER_SOL).toString()
        )
      )
    );
    const { blockhash } = await connection.getRecentBlockhash("finalized");
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = new PublicKey(walletKey);
    //@ts-ignore
    transaction.investorPublicKey = data.wallet;
    return transaction;
  } catch (e: any) {
    console.log("createTransaction error ==>", e);
    return data.wallet;
  }
};

export const signMultipleTransactions = async (list: any[]) => {
  try {
    const signTransactionList = await window.solana.signAllTransactions(list);
    const signAndSendTransactionList = await Promise.allSettled(
      signAndSendTransactions(signTransactionList)
    );
    const confirmedTransactions = await Promise.allSettled(
      confirmTransactions(signAndSendTransactionList)
    );
    const compare = confirmedTransactions.map((el, i) => {
      return { el, investorPublicKey: list[i].investorPublicKey };
    });
    console.log("compare", compare);
    return compare;
  } catch (error) {
    console.log(
      "signMultipleTransactions error =================================================================",
      error
    );
  }
};

export const signAndSendTransactions = (list: any) => {
  const signAndSendTransactionList = [];
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    const transaction = connection.sendRawTransaction(element.serialize());
    signAndSendTransactionList.push(transaction);
  }
  return signAndSendTransactionList;
};

export const confirmTransactions = (list: any) => {
  const confirmedTransactions = [];
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    console.log("element =>=>", element);
    if (element.status === "fulfilled") {
      const done = connection.confirmTransaction(element.value);
      confirmedTransactions.push(done);
    } else {
      confirmedTransactions.push(element);
    }
  }
  return confirmedTransactions;
};
