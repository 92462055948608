export enum EVestingNames {
  SEED_SALE = "seed",
  STRATEGIC = "strategic",
  PUBLIC = "public",
  PRIVATE = "private",
  SEED_2 = "seed2",
}

export enum EPrivateVestingNames {
  LUQUIDITY = "luquidity",
  MARKETING = "marketing",
  TEAM = "team",
  ADVISORY = "advisory",
  TREASURY = "treasury",
}

export const VestingNames = {
  ...EVestingNames,
  ...EPrivateVestingNames,
} as const;
export type EAllVestingNames = typeof VestingNames;
export type KeyNamesType = keyof EAllVestingNames;

export const privateNames = [
  "LUQUIDITY",
  "MARKETING",
  "TEAM",
  "ADVISORY",
  "TREASURY",
] as Array<KeyNamesType>;
