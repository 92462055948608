import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Connection, PublicKey, Transaction } from "@solana/web3.js";
import { Alert, CircularProgress, Container, Snackbar } from "@mui/material";
import { VestingNames, privateNames } from "../../types";
import { Element } from "../../components/Element/Element";
import { TokenVesting } from "token-vesting-api";
import { WithdrawFromVestingInstruction } from "token-vesting-api/dist/schema";
import { LoadingButton } from "@mui/lab";
import Caption from "../../components/Title/Caption";
import { useWalletPublicKey } from "../../hooks/usePublicKey";

export const ActionForAll = () => {
  const { error, newWalletKey } = useWalletPublicKey();
  const [isSnackbarVisible, setSnackbarVisible] = useState<boolean>(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
  const [snackbarErrorMsg, setSnackbarErrorMsg] = useState<string>("");
  const [confirmedList, setConfirmedList] = useState<number>(0);

  useEffect(() => {
    if (error) {
      handleOpenSnackBar();
      setSnackbarErrorMsg(error?.message);
    }
  }, [error]);

  const handleClickOpen = async () => {
    setIsBtnDisabled(true);
    try {
      const transactionsList: Transaction[] = [];
      const connection = new Connection(process.env.REACT_APP_NETWORK);
      for (const name of privateNames) {
        const item = new TokenVesting(
          connection,
          new PublicKey(process.env.REACT_APP_PROGRAM_ID),
          new PublicKey(process.env.REACT_APP_MINT),
          new PublicKey(newWalletKey as PublicKey),
          new PublicKey(process.env.REACT_APP_CREATOR),
          new PublicKey(process.env.REACT_APP_CREATOR),
          VestingNames[name]
        );
        const walletAddress =
          process.env[`REACT_APP_${VestingNames[name]}`] ?? "";
        const walletAddressPK = new PublicKey(walletAddress);
        console.log(VestingNames[name]);

        const token = await item.getVestingStatistic(walletAddressPK);
        const transaction = await item.withdrawFromVesting(
          walletAddressPK,
          new WithdrawFromVestingInstruction(token.availableToWithdrawTokens)
        );
        const block = await connection.getRecentBlockhash("confirmed");
        console.log("block", block);

        const { blockhash } = block;
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = newWalletKey;
        transactionsList.push(transaction);

        const sign = await window.solana.signAndSendTransaction(transaction);
        console.log("sign normal === ", sign);

        const confirmed = await connection.confirmTransaction(sign.signature);
        console.log("confirmed === ", confirmed);
        if (confirmed) {
          setConfirmedList((prevState) => prevState + 1);
        }
      }
    } catch (error: any) {
      handleOpenSnackBar();
      setSnackbarErrorMsg(error.message);
      console.log("handleClickOpen error === ", error);
    } finally {
      setIsBtnDisabled(false);
    }
  };

  const handleCloseSnackBar = () => {
    setSnackbarVisible(false);
  };

  const handleOpenSnackBar = () => {
    setSnackbarVisible(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          p: "20px",
          flexDirection: { xs: "column", md: "column" },
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", md: "flex" },
            flexDirection: { xs: "row" },
            justifyContent: { xs: "space-around" },
            width: "100%",
            paddingY: 1,
            flexWrap: "wrap",
          }}
        >
          {newWalletKey &&
            privateNames.map((el, i) => (
              <Element key={i} name={el} newLocalWalletKey={newWalletKey} />
            ))}
        </Box>
      </Box>
      <Box sx={sxStyles.buttonContainer}>
        <Box sx={{ width: { md: "50%", xs: "90%" } }}>
          <LoadingButton
            color="primary"
            onClick={handleClickOpen}
            fullWidth
            variant="contained"
            loading={isBtnDisabled}
            loadingIndicator={
              <Container sx={sxStyles.loadingContainer}>
                <CircularProgress size={24} color={"info"} />
                <Caption
                  sx={sxStyles.loadingText}
                  text={confirmedList + "/" + privateNames.length}
                />
              </Container>
            }
            disabled={isBtnDisabled}
            sx={sxStyles.loadingButton}
          >
            Claim
          </LoadingButton>
        </Box>
      </Box>
      <Snackbar
        sx={sxStyles.snackbar}
        open={isSnackbarVisible}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert severity="error" sx={sxStyles.alert}>
          {snackbarErrorMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

const sxStyles = {
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    mb: { xs: 0, md: "28px" },
  },
  tab: {
    maxHeight: "40px",
    textTransform: "none",
    border: "1px solid #D3D3D3",
    borderBottom: 0,
    boxSizing: "border-box",
    borderRadius: "4px 4px 0px 0px",
    margin: "0 4px",
    padding: 1,
    fontSize: { xs: 14, md: 16 },
    height: 40,
    minHeight: 40,
    minWidth: "auto",
    "&.Mui-selected": {
      backgroundColor: "#1395ff",
      color: "#fff!important",
      fontWeight: "bold",
      height: 40,
      minHeight: 40,
    },
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: "divider",
    height: "40px",
    minHeight: "40px",
    display: "flex",
    flexDirection: "column",
  },
  contractAddress: {
    color: "rgba(19, 149, 255, 1)",
    paddingX: 1,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // maxWidth: "50%",
  },
  snackbar: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    left: 0,
    right: 0,
  },
  alert: {
    width: { xs: "90%", md: "40%" },
    display: "flex",
    justifyContent: "center",
  },
  loadingButton: {
    height: 56,
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingText: {
    px: 2,
  },
} as const;
