import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Container from "../../components/Container/Container";
import LinearProgressWithLabel from "../../components/Progress/Progress";
import BlueTitle from "../../components/Title/BlueTitle";
import Caption from "../../components/Title/Caption";
import Heading from "../../components/Title/Heading";
import moment from "moment";
import { Connection, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { TokenVesting } from "token-vesting-api";
import Button from "../../components/Button/Button";
import {
  VestingStatistic,
  VestingTypeStatistic,
} from "token-vesting-api/dist/query";
import ClaimModal from "../../components/ClaimModal/ClaimModal";
import { converterBN } from "../../utils";
import CongratulationsModal from "../../components/CongratulationsModal/CongratulationsModal";
import { WithdrawFromVestingInstruction } from "token-vesting-api/dist/schema";
import BigNumber from "bignumber.js";
import { CircularProgress } from "@mui/material";
import BN from "bn.js";
import { useWalletPublicKey } from "../../hooks/usePublicKey";
import { EVestingNames } from "../../types";

interface SeedSaleProps {
  name: string;
}

interface IValues {
  total: string;
  released: string;
  available: string;
  claimed: string;
}

const initialValues: IValues = {
  total: "0",
  released: "0",
  available: "0",
  claimed: "0",
};

const SeedSale: React.FC<SeedSaleProps> = ({ name }) => {
  const { newWalletKey } = useWalletPublicKey();
  const [values, setValues] = useState<IValues>(initialValues);
  const [connection, setConnection] = useState<Connection>();
  const [token, setToken] = useState<TokenVesting>();
  const [open, setOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<VestingStatistic>();
  const [vestingType, setVestingType] = useState<VestingTypeStatistic>();
  const [cliffTime, setCliffTime] = useState<string>("00:00:00");
  const isSeed = name === EVestingNames.SEED_SALE || name === EVestingNames.SEED_2;

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        vestingType &&
        vestingType.vesting_schedule &&
        vestingType.vesting_schedule.cliff
      ) {
        const now = new BN(moment().unix());
        const secondsInHour = new BN(3600);
        const hh = vestingType.vesting_schedule.cliff
          .sub(now)
          .divn(3600)
          .toString();
        const mm = vestingType.vesting_schedule.cliff
          .sub(now)
          .mod(secondsInHour)
          .divn(60)
          .toString();

        const ss = vestingType.vesting_schedule.cliff
          .sub(now)
          .mod(secondsInHour)
          .modn(60)
          .toString();

        const formatter = (str: string): string =>
          vestingType?.vesting_schedule?.cliff
            .sub(new BN(moment().unix()))
            .gtn(0)
            ? `${str.length < 2 ? "0" + str : str}`
            : "00";

        setCliffTime(`${formatter(hh)}:${formatter(mm)}:${formatter(ss)}`);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [vestingType]);

  useEffect(() => {
    setConnection(new Connection(process.env.REACT_APP_NETWORK));
    newWalletKey &&
      setToken(
        new TokenVesting(
          new Connection(process.env.REACT_APP_NETWORK),
          new PublicKey(process.env.REACT_APP_PROGRAM_ID),
          new PublicKey(process.env.REACT_APP_MINT),
          new PublicKey(newWalletKey),
          new PublicKey(process.env.REACT_APP_CREATOR),
          new PublicKey(process.env.REACT_APP_ADMIN),
          name
        )
      );
    return () => {};
  }, [name, newWalletKey]);

  useEffect(() => {
    if (token) {
      token
        .getVestingTypeStatistic()
        .then((data) => {
          setVestingType(data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setError(true);
          console.log("getVestingType error === ", e);
        });
      newWalletKey &&
        token
          .getVestingStatistic(newWalletKey)
          .then((data) => {
            if (!data.isActive) {
              setValues(initialValues);
            } else {
              setData(data);
              setValues({
                total: converterBN(data.allTokens),
                released: converterBN(data.unlockedTokens),
                available: converterBN(data.availableToWithdrawTokens),
                claimed: converterBN(data.withdrawn_tokens),
              });
            }
          })
          .catch((error: Error) => {
            if (error.message.includes("Vesting Account does not exist")) {
              setError(true);
            }
            console.log("getVestingStatistic error === ", error);
          });
    }
  }, [newWalletKey, token]);

  const handleClaim = () => {
    console.log("window.solana.isConnected", window.solana.isConnected);

    setIsLoading(!isLoading);
    newWalletKey &&
      connection &&
      token &&
      data &&
      data.availableToWithdrawTokens &&
      token
        .withdrawFromVesting(
          newWalletKey,
          new WithdrawFromVestingInstruction(isSeed ? data.availableToWithdrawTokens.sub(new BN(LAMPORTS_PER_SOL)) : data.availableToWithdrawTokens)
        )
        .then((transaction) => {
          console.log("withdrawFromVesting", transaction);
          connection
            .getRecentBlockhash("confirmed")
            .then((block) => {
              const { blockhash } = block;
              transaction.recentBlockhash = blockhash;
              transaction.feePayer = newWalletKey;

              window.solana
                .signAndSendTransaction(transaction)
                .then((sign: { signature: string }) => {
                  console.log("sign === ", sign);

                  connection
                    .confirmTransaction(sign.signature)
                    .then((signature) => {
                      console.log("signature", signature);
                      handleClose();
                      handleOpen();
                    })
                    .catch((e) => {
                      console.log("signature", e);
                      setIsError(!isError);
                    });
                })
                .catch((e: any) => {
                  console.log("test == ", e);
                  setIsError(!isError);
                });
            })
            .catch((e) => {
              console.log("hash", e);
              setIsError(!isError);
            });
        })
        .catch((e) => {
          console.log("withdrawFromVesting", e);
          setIsError(!isError);
        });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsError(false);
    setIsLoading(false);
  };

  const handleExit = () => {
    setIsOpen(false);
    newWalletKey &&
      token &&
      token
        .getVestingStatistic(newWalletKey)
        .then((data) => {
          setData(data);
          setValues({
            total: converterBN(data.allTokens),
            released: converterBN(data.unlockedTokens),
            available: converterBN(data.availableToWithdrawTokens),
            claimed: converterBN(data.withdrawn_tokens),
          });
        })
        .catch((error: Error) => {
          if (error.message.includes("Vesting Account does not exist")) {
            setError(true);
          }
          console.log("handleExit getVestingStatistic error === ", error);
        });
  };

  const { total, available, claimed, released } = values;

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "300px",
          flexDirection: "column",
        }}
      >
        <CircularProgress
          style={{ color: "#1395FF" }}
          thickness={6}
          size={50}
        />
        <Caption sx={{ p: 2 }} text={"Processing"} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "300px",
        }}
      >
        <BlueTitle
          text={"You are not in this investor group."}
          isUpperCase={false}
        />
      </Box>
    );
  }

  return (
    <>
      <ClaimModal
        available={data ? isSeed ? converterBN(data.availableToWithdrawTokens.sub(new BN(LAMPORTS_PER_SOL))) : converterBN(data.availableToWithdrawTokens) : ""}
        customText={isSeed ? "You will receive the total amount available ELU" : undefined}
        {...{ open, isError, isLoading, handleClose, handleClaim }}
      />
      <CongratulationsModal
        wallet={newWalletKey}
        {...{ isOpen }}
        handleClose={handleExit}
      />
      <Box
        sx={{
          width: "100%",
          paddingX: { xs: 0, lg: 10 },
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "space-around" },
            alignItems: "center",
            py: "20px",
            flexDirection: { xs: "column", md: "column", lg: "row" },
          }}
        >
          <Box sx={{ paddingY: { xs: 1, md: 0 } }}>
            <BlueTitle
              text={
                vestingType &&
                vestingType.vesting_schedule &&
                vestingType.vesting_schedule.start_time
                  ? moment
                      .unix(
                        +vestingType?.vesting_schedule?.start_time.toString()
                      )
                      .format("L")
                  : ""
              }
            />
            {/*starttime*/}
            <Caption text={"Token Generation Event day"} />
          </Box>
          <Box sx={{ paddingY: { xs: 1, md: 0 } }}>
            <BlueTitle
              text={
                vestingType && vestingType.vesting_schedule
                  ? `${new BigNumber(total)
                      .multipliedBy(
                        vestingType?.vesting_schedule?.initial_unlock
                      )
                      .toString()} ELU`
                  : "0 ELU"
              }
            />
            <Caption text={"Tokens released on Token Generation Event"} />
          </Box>
          <Box sx={{ paddingY: { xs: 1, md: 0 } }}>
            <BlueTitle text={cliffTime} />
            <Caption text={"Cliff period ending in"} />
          </Box>
        </Box>
        <LinearProgressWithLabel
          value={new BigNumber(total).dividedBy(released).toString()}
          topText={released + " ELU"}
          topStickyText={total + " ELU"}
          bottomText={moment(new Date()).format("L")} //current time
          bottomStickyText={
            vestingType &&
            vestingType.vesting_schedule &&
            vestingType.vesting_schedule.end_time
              ? moment
                  .unix(+vestingType?.vesting_schedule?.end_time.toString())
                  .format("L")
              : ""
          }
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            py: "20px",
            flexDirection: { xs: "column", md: "column", lg: "row" },
          }}
        >
          <Box
            sx={{
              display: { xs: "flex", md: "flex" },
              flexDirection: { xs: "row" },
              justifyContent: { xs: "space-around" },
              width: { xs: "100%", lg: "50%" },
              paddingY: 1,
            }}
          >
            <Container
              sx={{
                flex: 1,
                marginX: { xs: "4px", md: 1 },
                width: { xs: 130, md: 160 },
              }}
            >
              <Caption text={"Total Tokens Vested"} />
              <Heading
                sx={{ textAlign: { xs: "center" } }}
                text={total + " ELU"}
              />
            </Container>
            <Container
              sx={{
                flex: 1,
                marginX: { xs: "4px", md: 1 },
                width: { xs: 130, md: 160 },
              }}
            >
              <Caption text={"Tokens Released"} />
              <Heading
                text={released + " ELU"}
                sx={{ textAlign: { xs: "center" } }}
              />
            </Container>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "flex" },
              flexDirection: { xs: "row" },
              justifyContent: { xs: "space-around" },
              width: { xs: "100%", lg: "50%" },
              paddingY: 1,
            }}
          >
            <Container
              sx={{
                flex: 1,
                marginX: { xs: "4px", md: 1 },
                width: { xs: 130, md: 160 },
              }}
            >
              <Caption text={"Tokens Claimed"} />
              <Heading
                sx={{ textAlign: { xs: "center" } }}
                text={claimed + " ELU"}
              />
            </Container>
            <Container
              sx={{
                flex: 1,
                marginX: { xs: "4px", md: 1 },
                width: { xs: 130, md: 160 },
              }}
            >
              <Caption text={"Tokens Available to Claim"} />
              <Heading
                sx={{ textAlign: { xs: "center" } }}
                text={available + " ELU"}
              />
            </Container>
          </Box>
        </Box>
        <Box sx={sxStyles.buttonContainer}>
          <Box sx={{ width: { md: "30%", xs: "90%" } }}>
            <Button
              title="Claim!"
              disable={available === "0" || (isSeed &&  available === '1')}
              isIconVisible={false}
              onClick={handleClickOpen}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#F2FBFF",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "space-around", md: "space-between" },
          flexDirection: { xs: "column", lg: "row" },
          p: { xs: 2 },
          paddingX: { md: 10, xs: 0 },
          boxSizing: "border-box",
          mt: { xs: 6, md: 0 },
          cursor: "default",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Caption
            sx={{ width: { xs: "100%", md: "max-content" } }}
            text={"Program address:"}
          />
          <Caption
            sx={sxStyles.contractAddress}
            text={process.env.REACT_APP_PROGRAM_ID}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Caption
            sx={{ width: { xs: "100%", md: "max-content" } }}
            text={"Tokens are vested to:"}
          />
          <Caption
            sx={sxStyles.contractAddress}
            text={newWalletKey?.toString() ?? ""}
          />
        </Box>
      </Box>
    </>
  );
};

const sxStyles = {
  buttonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    mb: { xs: 0, md: 1 },
  },
  tab: {
    maxHeight: "40px",
    textTransform: "none",
    border: "1px solid #D3D3D3",
    borderBottom: 0,
    boxSizing: "border-box",
    borderRadius: "4px 4px 0px 0px",
    margin: "0 4px",
    padding: 1,
    fontSize: { xs: 14, md: 16 },
    height: 40,
    minHeight: 40,
    minWidth: "auto",
    "&.Mui-selected": {
      backgroundColor: "#1395ff",
      color: "#fff!important",
      fontWeight: "bold",
      height: 40,
      minHeight: 40,
    },
  },
  tabsContainer: {
    borderBottom: 1,
    borderColor: "divider",
    height: "40px",
    minHeight: "40px",
    display: "flex",
    flexDirection: "column",
  },
  contractAddress: {
    color: "rgba(19, 149, 255, 1)",
    paddingX: 1,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // maxWidth: "50%",
  },
} as const;

export default SeedSale;
