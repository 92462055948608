import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Alert,
  Snackbar,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  SelectChangeEvent,
} from "@mui/material";
import Logo from "../../assets/logo.svg";
import styles from "./DevestInvestor.module.css";
import { LoadingButton } from "@mui/lab";
import { EPrivateVestingNames, EVestingNames } from "../../types";
import Caption from "../../components/Title/Caption";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import { useLocation } from "react-router-dom";
import { getTokenDevesting } from "../../utils";
import { TokenDevesting } from "token-vesting-api/dist/token-devesting";
import { Connection, PublicKey } from "@solana/web3.js";
import CloseIcon from "@mui/icons-material/Close";

export const DevestInvestor = () => {
  const { search } = useLocation();
  const [walletKey, setWalletKey] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSnackbarVisible, setSnackbarVisible] = useState<boolean>(false);
  const [snackbarErrorMsg, setSnackbarErrorMsg] = useState<string>("");
  const [value, setValue] = useState<string>(search.split("=")[1] ?? "");
  const [tokenDevesting, setTokenDevesting] = useState<TokenDevesting>();

  useEffect(() => {
    const getWallet = async () => {
      try {
        const resp = await window.solana.connect();
        const newLocalWalletKey: string = resp.publicKey.toString();
        setWalletKey(newLocalWalletKey);
      } catch (error) {
        console.log("error", error);
        setIsError(true);
      }
    };
    getWallet();
  }, []);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (type) {
      const setTokenDevestingValue = async () => {
        const tokenDevesting = await getTokenDevesting(type);
        setTokenDevesting(tokenDevesting);
      };
      setTokenDevestingValue();
    }
  }, [type]);

  const onChangeType = (e: SelectChangeEvent<EVestingNames | string>) => {
    const value = e.target.value;
    setType(value);
  };

  const onDevestClick = async () => {
    setLoading(true);
    if (!tokenDevesting) {
      throw new Error("Cannot create Devesting");
    } else {
      try {
        const walletPk = new PublicKey(value);
        const statistics = await tokenDevesting?.getVestingStatistic(walletPk);
        console.log("statistics === ", statistics);
        if (!statistics?.isActive) {
          handleOpenSnackBar();
          throw new Error("Wallet already devested");
        }

        const multisigData = await tokenDevesting?.getMultisigData();
        const require_signers = multisigData?.require_signers;
        if (
          !require_signers?.find((el) => el.equals(new PublicKey(walletKey)))
        ) {
          console.log("requiredSigners === ", require_signers);
          throw new Error("Your Wallet invalid");
        }

        const creator = await tokenDevesting.findDevestingCreator(walletPk);
        if (creator) {
          const devestingStatistics =
            await tokenDevesting?.getDevestingStatistic(creator, walletPk);
          const signed = devestingStatistics?.signed;
          const isLox = signed?.find((el) =>
            el.equals(new PublicKey(walletKey))
          );
          if (isLox) {
            throw new Error("You already signed with this wallet");
          }
        }

        const transaction = await tokenDevesting?.signDevestAccount(
          walletPk,
          creator
        );

        const connection = new Connection(process.env.REACT_APP_NETWORK);
        const { blockhash } = await connection.getRecentBlockhash("confirmed");
        if (transaction) {
          transaction.recentBlockhash = blockhash;
          transaction.feePayer = new PublicKey(walletKey);

          const sign = await window.solana.signAndSendTransaction(transaction);
          const done = await connection.confirmTransaction(sign.signature);
          console.log("done === ", done);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          setSnackbarErrorMsg(error.message);
          handleOpenSnackBar();
          console.log("error === ", error);
        }
      } finally {
        setLoading(false);
        setValue("");
        setType("");
      }
    }
  };

  const handleCloseSnackBar = () => {
    setSnackbarVisible(false);
  };

  const handleOpenSnackBar = () => {
    setSnackbarVisible(true);
  };

  return (
    <Box sx={sxStyles.mainContainer}>
      <Box sx={sxStyles.topContainer}>
        <div className={styles.logo_container}>
          <img alt={"logo"} src={Logo} className={styles.logo} />
        </div>
      </Box>
      <Box sx={sxStyles.wrapper}>
        {!isError ? (
          <Box sx={sxStyles.formContainer}>
            <FormControl disabled={isLoading} fullWidth sx={{ mb: 2 }}>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={type}
                label="Type"
                onChange={onChangeType}
              >
                <MenuItem value={EVestingNames.SEED_SALE}>Seed Sale</MenuItem>
                <MenuItem value={EVestingNames.STRATEGIC}>Strategic</MenuItem>
                <MenuItem value={EPrivateVestingNames.ADVISORY}>
                  Advisory
                </MenuItem>
                <MenuItem value={EVestingNames.PRIVATE}>Private</MenuItem>
              </Select>
              <Divider />
              <TextField
                sx={sxStyles.input}
                onChange={onChange}
                value={value}
                disabled={!type.length}
                label="Wallet Address"
                color="warning"
              />
            </FormControl>
            <LoadingButton
              color="warning"
              onClick={onDevestClick}
              sx={sxStyles.loadingButton}
              fullWidth
              loadingPosition="start"
              startIcon={<CloseIcon />}
              variant="contained"
              loading={isLoading}
              disabled={isLoading || !value.length}
            >
              Devest
            </LoadingButton>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FmdBadIcon sx={{ color: "#f7bab9", fontSize: 68 }} />
            <Caption
              sx={{ p: 2 }}
              text={"Sorry, something went wrong. Try again later."}
            />
          </Box>
        )}
      </Box>
      <Snackbar
        sx={sxStyles.snackbar}
        open={isSnackbarVisible}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert severity="error" sx={sxStyles.alert}>
          {snackbarErrorMsg}
          {/* Invalid Wallet Address or Amount */}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const sxStyles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  topContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    width: "100%",
  },
  wrapper: {
    width: "100%",
    padding: { xs: 0, md: 0 },
    display: "flex",
    justifyContent: "center",
  },
  formContainer: {
    width: { md: "50%", xs: "100%" },
    minHeight: "300px",
    paddingX: { xs: 1, lg: 2 },
    pb: 3,
    pt: { lg: 0, xs: 1 },
  },
  addIcon: {
    borderRadius: 25,
    // background: "#1395ff",
    background: "black",
    color: "white",
    opacity: "0.5",
    "&.MuiButtonBase-root:hover": {
      opacity: "1!important",
      background: "black",
    },
  },
  row: {
    height: "40vh",
    py: 1,
  },
  loadingButton: {
    height: 56,
  },
  chipContainer: {
    pt: 1,
    overflow: "scroll",
    maxHeight: "50vh",
  },
  input: {
    mt: 2,
  },
  chip: {
    my: 0.5,
  },
  area: {
    height: "30vh",
    width: "99%",
    margin: 0,
  },
  snackbar: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    left: 0,
    right: 0,
  },
  alert: {
    width: { xs: "90%", md: "40%" },
    display: "flex",
    justifyContent: "center",
  },
} as const;
