import { Connection, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { TokenVesting } from "token-vesting-api";
import { TokenDevesting } from "token-vesting-api/dist/token-devesting";
import path from "path";
const dotenv = require("dotenv");
const bigNumber = require("bignumber.js");
const envFile =
  process.env.NODE_ENV === "development"
    ? path.resolve(__dirname, `../../.env.${process.env.NODE_ENV}`)
    : path.resolve(__dirname, `../../.env.${process.env.NODE_ENV}`);
dotenv.config({ path: envFile });

export const converterBN = (number: { toString: () => string }): string =>
  new bigNumber(number.toString()).dividedBy(LAMPORTS_PER_SOL).toString();

export const getTokenDevesting = async (name: string) => {
  if ("solana" in window) {
    const resp = await window.solana.connect();
    const newLocalWalletKey = resp.publicKey.toString();
    const tokenVesting = new TokenDevesting(
      new Connection(process.env.REACT_APP_NETWORK),
      new PublicKey(process.env.REACT_APP_PROGRAM_ID),
      new PublicKey(process.env.REACT_APP_MINT),
      new PublicKey(newLocalWalletKey),
      new PublicKey(process.env.REACT_APP_CREATOR),
      new PublicKey(process.env.REACT_APP_ADMIN),
      name
    );
    return tokenVesting;
  }
};

export const getTokenVesting = async (name: string) => {
  const isPhantomInstalled = window.solana && window.solana.isPhantom;
  console.log("solana" in window, "isPhantomInstalled", isPhantomInstalled);
  if ("solana" in window) {
    const resp = await window.solana.connect();
    const newLocalWalletKey = resp.publicKey.toString();
    const tokenVesting = new TokenVesting(
      new Connection(process.env.REACT_APP_NETWORK),
      new PublicKey(process.env.REACT_APP_PROGRAM_ID),
      new PublicKey(process.env.REACT_APP_MINT),
      new PublicKey(newLocalWalletKey),
      new PublicKey(process.env.REACT_APP_CREATOR),
      new PublicKey(process.env.REACT_APP_ADMIN),
      name
    );
    return tokenVesting;
  } else {
    window.open("https://phantom.app/", "_blank");
  }
};

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_NETWORK: string;
      REACT_APP_PROGRAM_ID: string;
      REACT_APP_MINT: string;
      REACT_APP_CREATOR: string;
      REACT_APP_ADMIN: string;
      REACT_APP_luquidity: string;
      REACT_APP_marketing: string;
      REACT_APP_team: string;
      REACT_APP_advisory: string;
      REACT_APP_treasury: string;
    }
  }
}

export const getPublicKey = async () => {
  const key = await window?.solana?.connect();
  const newLocalWalletKey = key.publicKey.toString();
  return { newLocalWalletKey };
};
