import React, { ReactElement } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Cabinet from "./pages/Cabinet/Cabinet";
import Login from "./pages/Login/Login";
import styles from "./App.module.css";
import Logout from "./pages/Logout/Logout";
import { StylesProvider } from "@mui/styles";
import RegisterInvestor from "./pages/RegisterInvestor/RegisterInvestor";
import { DevestInvestor } from "./pages/DevestInvestor/DevestInvestor";
import { ActionForAll } from "./pages/ActionForAll/ActionForAll";

interface PrivateRouteProps {
  children: ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const savedToken = localStorage.getItem("publicKey");
  const token = !!window.solana && !!savedToken;
  return token ? children : <Navigate to="/" />;
};

function App() {
  const location = useLocation();
  return (
    <StylesProvider injectFirst>
      <div
        className={
          location.pathname === "/cabinet" ? styles.App : styles.App_fullHeight
        }
      >
        <div className={styles.main_container}>
          <div className={styles.container}>
            <Routes>
              <Route index={false} path="/" element={<Login />} />
              <Route
                path="/cabinet"
                element={
                  <PrivateRoute>
                    <Cabinet />
                  </PrivateRoute>
                }
              />
              <Route path="/register-investor" element={<RegisterInvestor />} />
              <Route path="/devest-investor" element={<DevestInvestor />} />
              <Route path="/devest-private" element={<ActionForAll />} />
              <Route path="/logout" element={<Logout />} />
            </Routes>
          </div>
        </div>
      </div>
    </StylesProvider>
  );
}

const AppWrapper = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default AppWrapper;
