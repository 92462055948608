import { PublicKey } from "@solana/web3.js";
import { useCallback, useEffect, useState } from "react";

export const useWalletPublicKey = () => {
  const [newWalletKey, setNewWalletKey] = useState<PublicKey>();
  const [error, setError] = useState<Error | undefined>(undefined);

  const connectSolana = useCallback(async () => {
    try {
      if ("solana" in window) {
        const key = await window.solana?.connect();
        setNewWalletKey(new PublicKey(key.publicKey.toString()));
      }
    } catch (error: any) {
      setError(error);
      console.log("connectSolana error === ", error);
    }
  }, []);

  useEffect(() => {
    connectSolana();
  }, [connectSolana]);

  return { newWalletKey, error };
};
